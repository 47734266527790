<template>
  <table class="table">
    <thead>
      <tr>
        <th
          class="table-res"
          :class="activeLanguage.orderDate === 'Date' ? 'date' : 'dateDutch'"
        >
          {{ activeLanguage.orderDate }}
        </th>
        <th class="table-res inv">
          {{ activeLanguage.orderNum }}
        </th>
        <th class="table-res company">
          {{ activeLanguage.companyName }}
        </th>
        <th class="text-left name">
          {{ activeLanguage.name }}
        </th>
        <th class="table-res name">
          {{ activeLanguage.surname }}
        </th>
        <th class="table-res city">
          {{ activeLanguage.place }}
        </th>
        <th class="table-res amount">
          {{ activeLanguage.total }}
        </th>
        <th class="table-res amount">
          {{ activeLanguage.payed }}
        </th>
        <th class="table-res amount">
          {{ activeLanguage.balance }}
        </th>
        <th class="table-res text-center">
          {{ activeLanguage.credit }}
        </th>
        <th class="table-res text-center">
          {{ activeLanguage.send }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in orders">
        <td
          class="table-res"
          :class="activeLanguage.orderDate === 'Date' ? 'date' : 'dateDutch'"
        >
          {{ order.order_date }}
        </td>
        <td class="table-res inv">
          <div
            class="overflow inv"
            v-b-tooltip.hover.right.title="order.invoice"
          >
            {{ order.invoice }}
          </div>
          <div class="table-res small-date">{{ order.order_date }}</div>
        </td>
        <td class="table-res company">
          <div
            class="overflow company"
            v-b-tooltip.hover.right.title="order.company"
          >
            {{ order.company }}
          </div>
        </td>
        <td class="table-res name">
          <div
            class="overflow name"
            v-b-tooltip.hover.right.title="order.first_name"
          >
            {{ order.first_name }}
          </div>
        </td>
        <td class="table-res name">
          <div
            class="overflow name"
            v-b-tooltip.hover.right.title="order.last_name"
          >
            {{ order.last_name }}
          </div>
        </td>
        <td class="table-res city">
          <div class="overflow city" v-b-tooltip.hover.right.title="order.city">
            {{ order.city }}
          </div>
        </td>
        <td class="table-res amount"><span>€</span> {{ order.total }}</td>
        <td class="table-res amount"><span>€</span> {{ order.paid }}</td>
        <td class="table-res amount"><span>€</span> {{ order.saldo }}</td>
        <td v-b-tooltip.hover.right.title="activeLanguage.credit">
          <div class="arrTd" @click="showModal(order)">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </div>
          <div class="credit-modal">
            <b-modal
              @cancel="hideModal(order.invoice)"
              :ref="`creditOrderModal-${order.invoice}`"
              hide-footer
              hide-header
              size="lg"
            >
              <CreditOrder
                :ref="`OrderDetailsCreditOrder-${order.invoice}`"
                :activeLanguage="activeLanguageCredit.addCreditBlock"
                :orderData="orderData"
                :items="orderItems"
                @close="hideModal(order.invoice)"
                @creditIsAdded="creditIsAdded(order.invoice)"
              />
            </b-modal>
          </div>
        </td>
        <td
          class="arrTd"
          @click="sendOrder(order)"
          v-b-tooltip.hover.right.title="activeLanguage.send"
        >
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import CreditOrder from "@/components/orderDetailsView/CreditOrder";

export default {
  name: "cibTableView",
  props: {
    activeLanguage: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    activeLanguageCredit: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      orderItems: [],
      orderData: {},
    };
  },
  methods: {
    showModal(order) {
      this.getOrderDetails(order.invoice);
      this.$refs[`creditOrderModal-${order.invoice}`][0].show();
      this.$refs[
        `OrderDetailsCreditOrder-${order.invoice}`
      ][0].showCreditOrder();
    },
    hideModal(invoice) {
      this.$refs[`creditOrderModal-${invoice}`][0].hide();
    },
    creditIsAdded(invoice) {
      this.hideModal(invoice);
      this.$emit("orderIsCredited");
    },
    sendOrder(order) {
      billinkAxios
        .post(`app/pending-to-cib/send/${order.id}`)
        .then(({ data }) => {
          if (data.status === "success") {
            EventBus.$emit("showAlertModal", data.message);
          }
        })
        .catch((err) => err);
    },
    getOrderDetails(invoiceNr) {
      billinkAxios
        .get(`app/order-details/${invoiceNr}`)
        .then(({ data }) => {
          this.orderItems = data.items;
          this.orderData = {
            order_id: parseFloat(data.id),
            amount: parseFloat(data.order_info.outstanding),
            credit:
              parseFloat(data.order_info.subtotal) -
              parseFloat(data.order_info.credit),
          };
        })
        .catch((error) => console.error(error));
    },
  },
  components: {
    CreditOrder,
  },
};
</script>

<style scoped lang="scss">
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.amount,
.date,
.name,
.city {
  width: 100px;
}
.inv,
.company {
  width: 200px;
}
.dateDutch {
  width: 170px;
}
.arrTd {
  text-align: center;
  color: #538ac5;
  cursor: pointer;
  font-size: 16px;
}
.fa-stack-2x {
  border-radius: 50%;
  background: #c1a1c7;
}
@media (max-width: 768px) {
  .table {
    td,
    th {
      font-size: 10px;
      padding: 2px 6px;
      vertical-align: middle;
    }
    th {
      padding: 6px;
      &.openstaand,
      &.amount {
        width: 70px;
      }
    }
    .overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      width: 70px;
    }
    .tfoot-no-check td {
      text-align: center;
      & > * {
        padding: 5px;
        font-size: 11px;
        margin: 0 20px;
      }
    }
  }
}
@media (max-width: 1240px) {
  th i {
    display: none;
  }
  .cl .overflow {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
