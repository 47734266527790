<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <h1>
                   {{ activeLanguage.title }}
                </h1>
            </div>
            <cibOrdersTable
                :orders="orders"
                :activeLanguage="activeLanguage.ordersTable"
                :activeLanguageCredit="activeLanguageCredit"
                @orderIsCredited="getOrders"
            />
            <b-pagination
                align="center"
                :prev-text="activeLanguage.pagPrev"
                :next-text="activeLanguage.pagNext"
                :limit="6"
                @input="getOrders(pagData.currentPage)"
                :total-rows="pagData.maxOrders"
                v-model="pagData.currentPage"
                :per-page="20"
                v-if="pagData.maxOrders > 20"
            />
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
    import cibOrdersTable from '../../components/cibView/cibTableView'

	export default {
		name: "Cib",
        data() {
			return {
				orders: [],
				activeFilters: {},
				sorting: {
					sort: 'order_date',
					sort_dir: 'desc',
				},
				pagData: {
					currentPage: 1,
					maxOrders: 0,
				},
            }
        },
        mounted() {
			this.getOrders()
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getCIBLanguage',
				activeLanguageCredit: 'getOrderDetailsLanguage',
			})
		},
        methods: {
	        getOrders(page = 1) {
		        let params = {
			        filter: this.activeFilters,
			        page,
			        ...this.sorting
		        }

            	billinkAxios
                    .get('app/pending-to-cib/list', { params })
                    .then(({data}) => {
	                    this.orders = data.order_list
	                    this.pagData.currentPage = parseFloat(data.page)
	                    this.pagData.maxOrders = parseFloat(data.total_orders)
                    })
                    .catch(err => err)
            },
		},
        components: {
	        cibOrdersTable
        }
	}
</script>

<style scoped>
 .container {
     max-width: 1440px;
 }
</style>